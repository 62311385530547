import {configureStore} from '@reduxjs/toolkit';
import userReducer from './Slices/UserSlice.js';

function loadState() {
    const val = localStorage.getItem('state');

    if (!val) {
        localStorage.setItem('state', '');
    }

    try {
        return JSON.parse(val);
    } catch (_) {
        return {};
    }
}

export function saveState(state) {
    try {
        localStorage.setItem('state', JSON.stringify(state));
    } catch (_) {
    }
}

const preloaded = loadState();

const store = configureStore({
    reducer: {
        user: userReducer,
    },
    preloadedState: preloaded
});

store.subscribe(_ => {
    saveState(store.getState());
});

export default store;
