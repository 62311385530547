import {useSelector, useDispatch} from 'react-redux';
import {signout} from '../Slices/UserSlice';

export default function Navbar() {
    const dispatch = useDispatch();
    const isConnected = useSelector(
        state => state.user.isConnected
    );

    const onLogout = event => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(signout());
    };

    return (
        <div className="navbar">
          <ul>

            <li>
              <a href="/">
                <strong>BUDJETO</strong>
              </a>
            </li>

            <div className="navbar__separator"></div>

            {isConnected
             ? <li>
                 <a id="logOut" href="/" onClick={e => onLogout(e)}>
                   Déconnexion
                 </a>
               </li>
             : <></>
            }

          </ul>
        </div>
    );
}
