import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchUserByUsername} from '../Slices/UserSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api_signup} from '../api';

function ConnectForm({onClick, onConnect}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const status = useSelector(state => {
        return state.user.status;
    });

    const error = useSelector(state => {
        return state.user.error;
    });

    const onUsernameChange = event => {
        setUsername(_ => event.target.value);
    };

    const onPasswordChange = event => {
        setPassword(_ => event.target.value);
    };

    const onSubmit = event => {
        event.preventDefault();
        dispatch(fetchUserByUsername({username, password}));
    };

    return (
        <form>
          {status === 'pending'
           ? <>
               Connexion en cours
               <FontAwesomeIcon className="spinner" icon="fa-gear"/>
             </>
           : <></>
          }

          {status === 'done'
           ? <p className="passOk">Vous êtes connecté !</p>
           : <></>
          }

          {status === 'error'
           ? <p className="error-msg passErr">Error: {error.detail}</p>
           : <></>
          }

          <h1>Se connecter</h1>
          <input type="text" placeholder="nom d'utilisateur"
                 value={username}
                 onChange={e => onUsernameChange(e)}
          />
          <input type="password" placeholder="mot de passe"
                 value={password}
                 onChange={e => onPasswordChange(e)}
          />
          <input type="submit"
                 onClick={e => onSubmit(e)}
                 value="Connexion"/>

          <a href="/" onClick={e => onClick(e)}> je n'ai pas de compte</a>
        </form>
    );
}

function RegisterForm({onClick, onRegister}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [samePassword, setSamePassword] = useState(true);
    const [errors, setErrors] = useState([]);

    const err_messages = {
        'password': 'Mot de passe',
        'username': 'Nom d\'utilisateur',
        'email': 'Adresse email'
    };

    useEffect(function () {
        setSamePassword(_ =>
            password === confirmation
                || (password === '' && confirmation === '')
        );
    }, [password, confirmation]);

    const onUsernameChanged = function (event) {
        setUsername(_ => event.target.value);
    };

    const onPasswordChanged = function (event) {
        setPassword(_ => event.target.value);
    };

    const onEmailChanged = function (event) {
        setEmail(_ => event.target.value);
    };

    const onConfirmationChanged = function (event) {
        setConfirmation(_ => event.target.value);
    };

    const onSubmit = function(event) {
        event.preventDefault();

        if (samePassword) {
            onRegister(username, email, password)
                .then(result => {
                    if (result === null) {
                        setUsername(_ => "");
                        setEmail(_ => "");
                        setPassword(_ => "");
                        setConfirmation(_ => "");
                        setErrors(_ => []);
                        return;
                    }

                    let errs = [];

                    for (let e in result) {
                        errs.push([err_messages[e], result[e]]);
                    }

                    setErrors(_ => errs);
                });
        }
    };

    return (
        <form>
          <ul id="home-signup-error"> {
              errors.map((v, idx) => {
                  return <li className="err-msg" key={idx}>
                           <h1 className="err-msg__h">{v[0]}</h1>
                           <p>{v[1]}</p>
                           <br/>
                         </li>;
              })
          }
          </ul>

          <h1>Créer un nouveau compte</h1>

          <input type="text" placeholder="nom d'utilisateur"
                 value={username}
                 onChange={e => onUsernameChanged(e)}/>

          <input type="email"
                 placeholder="votre adresse email"
                 value={email}
                 onChange={e => onEmailChanged(e)}/>

          <input type="password" placeholder="mot de passe"
                 value={password}
                 onChange={e => onPasswordChanged(e)}/>

          {!samePassword
           ? <p className="passMsg passErr">
               Attention, les mots des passe sont différents
             </p>
           : <></>}

          <input type="password"
                 placeholder="confirmation du mot de passe"
                 value={confirmation}
                 onChange={e => onConfirmationChanged(e)}/>

          <input type="submit"
                 value="Inscription"
                 onClick={e => onSubmit(e)}/>

          <a href="/" onClick={e => onClick(e)}> j'ai déjà un compte</a>
        </form>
    );
}

export default function Home() {
    const [showRegister, setShowRegister] = useState(false);
    const isConnected = useSelector(state => state.user.isConnected);
    const username = useSelector(state => state.user.username);

    const onSwitchRegister = function(event) {
        event.stopPropagation();
        event.preventDefault();
        setShowRegister(old => !old);
    };

    const onRegister = async function(username, password, email) {
        const data = await api_signup(username, password, email);
        const json = await data.json();

        if (data.ok === false) {
            return json;
        }

        return null;
    };

    const onConnect = async function (username, password) {
        return null;
    };

    return (
        <div id="home-page">
          <header>
            <h1>
              Budjeto
            </h1>
          </header>

          <div className="center">

            <div className="center__desc">
              {isConnected
               ?
               <h2> Bienvenue {username} ! </h2>
               :
               <h2>Bienvenue !</h2>
              }

              <p>
                <strong>Bienvenue sur Budjeto</strong>, votre partenaire incontournable pour une gestion de budget simplifiée et efficace. Dans un monde où la maîtrise de ses finances personnelles est plus cruciale que jamais, Budjeto se positionne comme la solution idéale pour vous aider à reprendre le contrôle de votre argent.
              </p>
              <br/>
              <p>
                <strong>Rejoignez dès aujourd'hui la communauté Budjeto</strong> et commencez votre voyage vers une meilleure santé financière. Créez votre compte, définissez vos objectifs, et découvrez à quel point il est facile de faire de vos rêves financiers une réalité. Avec Budjeto, prenez enfin le contrôle de votre budget et construisez l'avenir financier que vous méritez.
              </p>
            </div>

            {!isConnected
             ?
             <div className="center__form">
               {showRegister
                ? <ConnectForm onClick={onSwitchRegister}
                           onConnect={onConnect}
               />
                : <RegisterForm onClick={onSwitchRegister}
                                     onRegister={onRegister}/>
               }
             </div>
             :
             <div>
             </div>
            }

          </div>
        </div>
    );
}
