import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faSackDollar,
    faSpinner,
    faGear,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faSackDollar,
    faSpinner,
    faGear,
    faRightFromBracket
);
