export function api_url() {
    let url = process.env.REACT_APP_API;

    if (process.env.REACT_APP_API === undefined) {
        url = 'http://localhost:8000';
    }

    let params = [url, 'v0'];

    for (let val of arguments) {
        params.push(val);
    }

    return params.join('/') + '/';
}

export async function api_post(request, body) {
    const data = await fetch(request, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(body)
    });

    return data;
}

export async function api_signup(username, email, password) {
    return api_post(api_url('signup'), {
        "username": username,
        "email": email,
        "password": password
    });
}
