import './Style/main.scss';
import './fontawesome.js';

import Home from './Pages/Home.jsx';
import Navbar from './Components/Navbar';

function App() {
    return (
        <div className="App">
          <Navbar/>
          <Home/>
        </div>
    );
}

export default App;
