import {api_url} from '../api';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const fetchUserByUsername = createAsyncThunk(
    'user/fetch_by_username',
    async ({username, password}) => {
        const res = await fetch(api_url('token'), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },

            body: JSON.stringify({
                username,
                password
            })
        });

        const data = await res.json();

        if (res.ok) {
            return {username, token: data, error: ''};
        } else {
            return {username, token: "", error: data};
        }
    }
);

export const userSlice = createSlice({
    name: 'user',

    initialState: {
        isConnected: false,
        username: '',
        token: '',
        status: '',
        error: ''
    },

    reducers: {
        signout: (state) => {
            state.isConnected = false;
            state.username = '';
            state.token = '';
            state.status = '';
            state.error = '';
        }
    },

    extraReducers: builder => {
        builder.addCase(fetchUserByUsername.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });

        builder.addCase(fetchUserByUsername.fulfilled, (state, action) => {
            if (action.payload.error === '') {
                state.isConnected = true;
                state.username = action.payload.username;
                state.token = {...action.payload.token};
                state.status = 'done';
                state.error = '';
            } else {
                state.status = 'error';
                state.error = action.payload.error;
            }
        });
    }
});

export const {signout} = userSlice.actions;
export default userSlice.reducer;
